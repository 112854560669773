import React, { Component } from 'react';
import './gridviewlang.scss';
import Footerlang from '../../components/footerlang/footerlang';
import Navlang from '../../components/navbarlang/navlang';
class Gridviewlang extends Component {
  constructor() {
    super()
    this.state = {
      images: [
        'IMG-20201016-WA0003.jpg',
        'IMG-20201016-WA0004.jpg',
        'IMG-20201016-WA0005.jpg',
        'IMG-20201016-WA0006.jpg',
        'IMG-20201016-WA0007.jpg',
        'IMG-20201016-WA0008.jpg',
        'IMG-20201016-WA0009.jpg',
        'IMG-20201016-WA0010.jpg',
        'IMG-20201016-WA0011.jpg',
        'IMG-20201016-WA0012.jpg',
        'IMG-20201016-WA0013.jpg',
        'IMG-20201016-WA0014.jpg',
        'IMG-20201016-WA0015.jpg',
        'IMG-20201016-WA0016.jpg',
        'IMG-20201016-WA0017.jpg',
        'IMG-20201016-WA0018.jpg',
        'IMG-20201016-WA0019.jpg',
        'IMG-20201016-WA0020.jpg',
        'IMG-20201016-WA0021.jpg',
        'IMG-20201016-WA0022.jpg',
        'IMG-20201016-WA0023.jpg',
        'IMG-20201016-WA0024.jpg',
        'IMG-20201016-WA0025.jpg',
        'IMG-20201016-WA0026.jpg',        
        'IMG-20201016-WA0028.jpg',
        'IMG-20201016-WA0029.jpg',
        'IMG-20201016-WA0030.jpg',
        'IMG-20201016-WA0031.jpg',
        'IMG-20201016-WA0032.jpg',
        'IMG-20201016-WA0033.jpg',
        'IMG-20201016-WA0034.jpg'
      ]
    }
  }
  render() {
    return (
      <div className="container" data-gridcnt="true">
        <Navlang />
        <div className="gridViewCnt">
          {this.state.images.map(function (item, index) {
            return <div>
              <img src={require('../../assets/' + item)} />
            </div>
          })}
        </div>
        <Footerlang />
      </div>
    );
  }
}

export default Gridviewlang;
