import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import './admin.scss';
const config = require('../../config');
class Admin extends Component {
    constructor() {
        super()
        this.state = {
            auth: false,
            username: '',
            password: '',
            invcre: 'doNotShow'
        }
    }
    login = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }
    auth = () => {        
        let sObj = JSON.stringify(this.state);
        const requestOptions = {
            method: 'POST',            
            body: sObj
        };
        fetch(config['baseurl']+'auth.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data['status']){
                    this.setState({auth: true})
                }else{
                    this.setState({
                        auth: false,                        
                        invcre: 'showError'
                    })
                }                
            });        
    }
    render() {
        if (this.state.auth) {
            return (
                <Redirect to='./admincontent' />
            )
        } else {
            return (
                <div className="loginComp">
                    <p>Login</p>
                    <p><input placeholder="user name" name="username" value={this.state.username} onChange={this.login} /></p>
                    <p><input placeholder="password" name="password" value={this.state.password} onChange={this.login} /></p>
                    <p><span className="btn btnPrimary floatRight" onClick={this.auth}>Submit</span></p>
                    <p><span className="btn floatRight majorTxtClr"><Link to="/home">Go to Home</Link></span></p>
                    <p className={this.state.invcre}>Invalid credentials</p>
                </div>
            )
        }
    }
}

export default Admin