import React, { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import './datepicker.scss';
function Datepicker(props) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#005cca',
      backgroundColor: 'transparent'
    }
  ]);
  function handleChange(event) {
    setState([event.selection])
    props.triggerFunc(event.selection);
  }
  return (
    <DateRange      
      editableDateInputs={true}
      onChange={handleChange}
      moveRangeOnFirstSelection={false}
      ranges={state}
      dragSelectionEnabled={props.dragSelectionEnabled !== undefined ? props.dragSelectionEnabled : true}
      moveRangeOnFirstSelection={props.moveRangeOnFirstSelection !== undefined ? props.moveRangeOnFirstSelection : true}
      showPreview={props.moveRangeOnFirstSelection !== undefined ? props.moveRangeOnFirstSelection : true}
    />
  );
}

export default Datepicker;