import React, { Component } from 'react';
import './dynamic.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from "react-router-dom";
import uuid from 'react-uuid';
// import Datepicker from '../../components/daterangepicker/datepicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const config = require('../../config');
class Dynamic extends Component {
    constructor() {
        super()
        this.state = {
            auth: true,
            username: '',
            password: '',
            valueInput: '',
            valueTextArea: '',
            file: '',
            content: '',
            galleryCnt: [],
            showGallery: true,
            showEvents: false,
            selectedDateGallery: new Date(),
            selectedDateEvent: '',
            startDate: '',
            contentTile: '',
            valueTileArea: '',
            popup: false,
            popupMsgStatus: false,
            popupMsg: ''
        }
    }
    componentDidMount() {
        this.getGalleryCnt();
    }
    onCalenderSelectGallery = (param) => {
        this.setState({
            selectedDateGallery: param.startDate
        });
    }
    onCalenderSelectEvent = (date) => {
        this.setState({
            startDate: date,
            selectedDateEvent: date,
            selectedDateGallery: date
        });
    }
    getGalleryCnt = () => {
        const requestOptions = {
            method: 'GET'
        };
        fetch(config['baseurl'] + 'fetchgallery.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    galleryCnt: data
                });
            });
        this.openGallery();
    }
    logout = () => {
        this.setState({ auth: false })
    }
    openEvents = () => {
        this.setState({
            showGallery: false,
            showEvents: true
        });
    }
    openGallery = () => {
        this.setState({
            showGallery: true,
            showEvents: false
        });
    }
    handleChangeTextArea = (event) => {
        this.setState({
            content: event.target.value,
            valueTextArea: event.target.value
        })
    }
    handleChangeInput = (event) => {
        this.setState({
            file: event.target.files,
            valueInput: event.target.value,
        })
    }
    handleChangeTitle = (event) => {
        this.setState({
            contentTile: event.target.value,
            valueTileArea: event.target.value
        })
    }
    uploadData = () => {
        this.setState({ popup: true });
        let formData = new FormData();
        formData.append('uploads', this.state.file[0]);
        formData.append('content', this.state.content);
        formData.append('date_str', this.state.selectedDateGallery.toGMTString())
        formData.append('title', this.state.contentTile)
        formData.append('newfilename', uuid());
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(config['baseurl'] + 'insertgallery.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.commit && data.q) {
                    this.setState({
                        content: '',
                        valueTextArea: '',
                        valueTileArea: '',
                        file: '',
                        valueInput: '',
                        startDate: ''
                    })
                    this.getGalleryCnt();
                    this.setState({
                        popupMsgStatus: true,
                        popupMsg: "Event uploaded successfully"
                    });
                }
            })
            .catch((e) => {
                this.setState({ popup: false });
            })
    }
    removeCard = (cardId) => {
        this.setState({ popup: true });
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 'id': cardId })
        };
        fetch(config['baseurl'] + 'removegallery.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.commit && data.q) {
                    this.getGalleryCnt();
                    this.setState({
                        popupMsgStatus: true,
                        popupMsg: "Event deleted successfully"
                    });
                }
            });
    }
    closePopUp = () => {
        this.setState({
            popup: false,
            popupMsgStatus: false,
            popupMsg: ""
        });
    }
    render() {
        if (this.state.auth) {
            let dynamic = this;
            return (
                <div className="container dynamicWrap">
                    <div className="logoutCnt">
                        <span>Admin</span>
                        <span className="logoutBtn floatRight" onClick={this.logout}>logout <FontAwesomeIcon icon={faSignOutAlt} /></span>
                    </div>
                    <div className="dynamicnt">
                        <div className="dyNav">                            
                            <p className="cursorPointer" onClick={this.openGallery}>Gallery</p>                            
                        </div>
                        <div className="dyCnt">
                            <div className={this.state.showEvents ? "eventBlk" : "hidden"}>
                                <div className="datePickerCnt">
                                    Select event date:<DatePicker selected={this.state.startDate} onChange={this.onCalenderSelectEvent} />
                                </div>
                                <div className="eventCnt">
                                    <textarea value={this.state.eventTextArea} onChange={this.eventHandleChangeTextArea} />
                                    <span className="btn btnPrimary" onClick={this.eventUploadData}>Upload</span>
                                </div>
                            </div>
                            <div className={this.state.showGallery ? "galleryBlk" : "hidden"}>
                                <div className="insertData">                                    
                                    <div>
                                        <div className="datePicker">Select event date:<DatePicker selected={this.state.startDate} onChange={this.onCalenderSelectEvent} popperPlacement="top" /></div>
                                        <p className="titleField">Enter event title:<input value={this.state.valueTileArea} onChange={this.handleChangeTitle} placeholder='Enter event title' /></p>
                                        <textarea placeholder="Enter about event" value={this.state.valueTextArea} onChange={this.handleChangeTextArea} />
                                        <input type="file" value={this.state.valueInput} onChange={this.handleChangeInput} />
                                        <span className="btn btnPrimary floatRight" onClick={this.uploadData}>Upload</span>
                                    </div>
                                </div>
                                <div className="eventCntCards">
                                    {this.state.galleryCnt.map(function (item, index) {
                                        return <div className="eventCard" data-id={item['id']} key={item['id']}>
                                            <div className="imgCnt">
                                                <img src={config['baseurl'] + 'resources/' + item['img']} />
                                            </div>
                                            <div className="eventTxt">
                                                <p>{item['textcnt']}</p>
                                            </div>
                                            <div className="actionIcon">
                                                <span onClick={() => dynamic.removeCard(item['id'])}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.popup ? "popup" : "popupHidden"}>
                        <div className={this.state.popupMsgStatus ? "hidden" : "loading"}>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className={this.state.popupMsgStatus ? "messageBox" : "hidden"}>
                            <p>{this.state.popupMsg}</p>
                            <span className="btn btnPrimary" onClick={this.closePopUp}>Okay</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <Redirect to='./admin' />
            )
        }
    }
}
export default Dynamic;