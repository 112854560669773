import React from 'react';
import './footerlang.scss';
import { Link } from "react-router-dom";
function Footerlang() {
  return (
    <div className="footer">
      <div className="footerCnt">
        <p className="lgscr"><Link to="/home">முதன்மை</Link> | <Link to="/gridview">காட்சியகங்கள்</Link> | <Link to="/gallery">நிகழ்வுகள்</Link> | <Link to='/contactus'>தொடர்பு கொள்ள</Link></p>
        <p className="copyright">Copyright © 2020 www.logachandraprabhu.com. Powered by : RS Websolutions</p>
      </div>
    </div>
  )
}
export default Footerlang;