import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt, faBars } from '@fortawesome/free-solid-svg-icons'

import './nav.scss';
function Nav() {
  return (
    <div className="menuBarWrap">
      <div className="menuBar">
        <div className="nameCnt">
          <img src={require('../../assets/person3.png')} />
          <div>
            <h3>Loga Chandraprabhu</h3>
            <p>Actor, Writer, Politician, Social activist</p>
          </div>
        </div>
        <div className="phoneNum">
          <p className="phNumBlk"><FontAwesomeIcon className="phoneIcon" icon={faPhoneSquareAlt} /> 9344301219</p>
          <p className="langSwitch"><span className="underline">English</span>&nbsp;|&nbsp;<span><Link to="/homelang">தமிழ்</Link></span></p>
        </div>
        <div className="phoneNumMbl">
          <p className="phNumBlk">
            <FontAwesomeIcon className="phoneIcon" icon={faPhoneSquareAlt} /> <a href="tel:9344301219">9344301219</a></p>
          <p className="langSwitch"><span className="underline">English</span>&nbsp;|&nbsp;<span><Link to="/homelang">தமிழ்</Link></span></p>
        </div>
        <div className="menuItems">
          <div className="btn" data-home="true">
            <Link to="/home">Home</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-grid="true">
            <Link to="/gridview">Gallery</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-gallery="true">
            <Link to="/gallery">Events</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-cont="true">
            <Link to='/contactus'>Contact&nbsp;Us</Link>
            <span className="selcted"></span>
          </div>
        </div>
      </div>
      <div className="mobileMenu">        
        <div className="menuItemsMbl">
          <div className="btn" data-home="true">
            <Link to="/home">Home</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-grid="true">
            <Link to="/gridview">Gallery</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-gallery="true">
            <Link to="/gallery">Events</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-cont="true">
            <Link to='/contactus'>Contact&nbsp;Us</Link>
            <span className="selcted"></span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Nav;