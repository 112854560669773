import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare, faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import './header.scss';
import { Link } from "react-router-dom";
class Header extends Component {
    constructor() {
        super();        
    }    
    render() {
        return (
            <div className="header" >
                <div className="nameHeader">Your name here</div>
                <div className="addressHeader">
                    <div className="iconCnt">
                        <p className="langSwitch"><span className="underline">English</span>&nbsp;|&nbsp;<span><Link to="/homelang">தமிழ்</Link></span></p>
                        <FontAwesomeIcon icon={faWhatsappSquare} />
                        <FontAwesomeIcon icon={faFacebookSquare} />
                        <FontAwesomeIcon icon={faTwitterSquare} />
                        <FontAwesomeIcon icon={faInstagramSquare} />
                        <FontAwesomeIcon icon={faLinkedin} />
                    </div>
                    <div className="addressCnt">
                        <p>G.S.Products</p>
                        <p>3/780,Nadu salai,Mangudi,</p>
                        <p>Velayapettai,</p>
                        <p>Kumbakonam-612702.</p>
                        <p>Phone:0435-2454677,9344301219</p>
                        <p>Email:winwaywater@gmail.com</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Header;
