import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './home.scss';
import Footer from '../../components/footer/footer';
import Nav from '../../components/navbar/nav';
import Clock from '../../components/clock/clock';
import BannerCarousel from '../../components/courosel/courosel';
const config = require('../../config');
class Home extends Component {
  constructor() {
    super();
    this.state = {
      currDate: new Date().toDateString(),
      currTime: this.formatAMPM(new Date()),
      dropDownEx: false,
      showDottedAnim: false,
      TemplesAroundKumbakonam: false,
      otherPilgrimages: false,
      nearestTourism: false,
      unescoTemples: false,
      currMnthEvent: []
    };
    this.myRef = React.createRef()
  }
  componentDidMount() {
    setInterval(() => this.tick(), 1000);
    fetch(config['baseurl'] + 'fetchgallery.php', {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        let currMnthEve = [];
        if (data.length > 5) {
          currMnthEve = data.slice(Math.max(data.length - 5, 1));
        } else {
          currMnthEve = data;
        }
        this.setState({
          currMnthEvent: currMnthEve
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.currTime);
  }
  onScroll = () => {
    let scrollTop = this.myRef.current.scrollTop
    if (scrollTop > 100) {
      this.setState({
        showDottedAnim: true
      })
    } else {
      this.setState({
        showDottedAnim: false
      })
    }
  }
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  tick() {
    this.setState({
      currTime: this.formatAMPM(new Date())
    });
  }
  showAbout() {
    document.getElementById('aboutCnt').scrollIntoView({ behavior: "smooth", block: "nearest" })
  }
  toggleClass(param) {
    if (param == 'TemplesAroundKumbakonam') {
      const currentState = this.state.TemplesAroundKumbakonam;
      this.setState({
        TemplesAroundKumbakonam: !currentState
      },
        () => {
          if (!currentState) {
            document.getElementById('TemplesAroundKumbakonam').scrollIntoView({ behavior: "smooth", block: "start" })
          }
        }
      );
    } else if (param == 'otherPilgrimages') {
      const currentState1 = this.state.otherPilgrimages;
      this.setState({
        otherPilgrimages: !currentState1
      },
        () => {
          if (!currentState1) {
            document.getElementById('otherPilgrimages').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
          }
        }
      );
    } else if (param == 'nearestTourism') {
      const currentState2 = this.state.nearestTourism;
      this.setState({
        nearestTourism: !currentState2
      }, () => {
        if (!currentState2) {
          document.getElementById('nearestTourism').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }
      });
    } else if (param == 'unescoTemples') {
      const currentState3 = this.state.unescoTemples;
      this.setState({
        unescoTemples: !currentState3
      },
        () => {
          if (!currentState3) {
            document.getElementById('unescoTemples').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
          }
        }
      );

    }
  }
  render() {
    return (
      <div className="container" data-homecnt="true" ref={this.myRef} onScroll={this.onScroll}>
        <Nav />
        <div className="curoselCnt">
          <div className="textCnt">
            {/* <p>I am Social activist</p>
            <p>I can Act</p> */}
            <p>Actor, Writer, Social activist</p>
            <span onClick={() => this.showAbout()} className="btn btnPrimary">Know more</span>
          </div>
          <div className="imgWrap">
            <div className="imgCnt"></div>
            <img src={require('../../assets/person.png')} />
          </div>
        </div>
        <div className="cardView">
          <div className="card">
            <p className="title">News & Events</p>
            <marquee data-events-continer='true' behavior="scroll" direction="up" scrolldelay="280">
              {this.state.currMnthEvent.map(function (item, index) {
                let thisDate = new Date(item['date']);
                thisDate = thisDate.getDate() + '/' + (Number(thisDate.getMonth()) + 1) + '/' + thisDate.getFullYear();
                return <p key={index} className="marqueeText">{thisDate + ' - ' + item['textcnt']}</p>
              })}
            </marquee>
          </div>
          <div className="card clkCnt">
            <div className="title">
              <p>{this.state.currDate}</p>
              <p>{this.state.currTime}</p>
            </div>
            <Clock />
          </div>
        </div>
        <div className="about">
          <div className="pointsWrap">
            <h1>About Me
            <span className="selcted"></span>
            </h1>
            <div className="pointsBlk" id="aboutCnt">
              <div className="points">1</div>
              <div className="pointsCnt">
                <h2>Actor</h2>
                <p>These are my short films...</p>
                <div className="iframeCnt">
                  <iframe src="https://www.youtube.com/embed/_A3T7iCmPNs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                  <iframe src="https://www.youtube.com/embed/3OSKGt9scFg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                  <iframe src="https://www.youtube.com/embed/PuvfrqKdxqU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                  <iframe src="https://www.youtube.com/embed/mXsRprQ1vqQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                  <iframe src="https://www.youtube.com/embed/mDUQQgxM3uc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                  <iframe src="https://www.youtube.com/embed/uAFILAHBv4s" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                  <iframe src="https://www.youtube.com/embed/P3Ba_H0h1sA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </div>
              </div>
            </div>
            <div className="pointsBlk">
              <div className="points">2</div>
              <div className="pointsCnt">
                <h2>Writer</h2>
                <p>My published books...</p>
                <BannerCarousel></BannerCarousel>
              </div>
            </div>
            <div className="pointsBlk">
              <div className="points pt3">3</div>
              <div className="pointsCnt">
                <h2>My Business Profile <span className="btn btnPrimary downloadFile"><a href={'../resources/PDF.pdf'} target="_blank">DownloadFile</a></span></h2>
                <div className="firstPara">
                  <p><b>EARLY LIFE</b><br />
                  Loga Chandraprabhu, who is currently writing a series titled "AANMMAVAI THEDI" in Kalki weekly has already wrote and published the book "Neerodu thaan pesinen" in 2017. In this book it is portrayed that the water and the writer are speaking to each other and water itself is giving a lot of information. The article series "Aanmmavai thedi" of kalki is being praised by all because it was about the stamping villages of Tamil nadu. It includes 16 different villages with various wondering characteristics. He was born in Kumbakonam on 23rd september of 1971, his father is from Veedur which is located in between Villupuram and Tindivanam. He started growing up in his mother's hometown, Kumbakonam as his father died in 1978 at his early age and he also has a younger sister Chandrakala born in 1972 but unfortunately died in 1987. As his mother couldn't afford to educate her two children, he was taken to the Kumbakonam Kottaiyur Vallalar Students Orphanage by his mother Sulochana and  his relatives in 1979.</p>
                </div>
                <p className="otherPara">He started studying in Vallalar Orphanage from Class 3 at the age of 8. The late honourable Ramadoss Anna who created the school was his Godfather. Then in 1986,after completing SSLC he left the school as the classes in the school were just up to SSLC. The school of the Orphanage which was the high school at that time has been upgraded to higher secondary school now. After finishing 10th class, he went to work in a soda company. While working, he completed his twelfth grade privately. As his hunger for education continued, he studied M.A history by post at Annamalai University Chidambaram and obtained his masters degree.</p>
                <p className="otherPara"><b>FAMILY AND BUSINESS LIFE</b><br />While working in a soda company, he began to write for weekly magazines such as the Kalki, Kumutham, Ananda Vikatan etc... that has been hanging out for public viewing since 1987 during marketing soda colours for punk stalls. As a result his snippet of laughter appeared in "Thai" weekly in 1987 His writings in journals include poems, question and answer, letter. He also used to write reader's suggestions about the articles in magazines. At that time he wrote in magazines with the pseudonym of Kudandhai L.Ramprabhu.There comes the important part of his life. On April 2,1992 the soda company in which he worked was sold to him.In that same year of February was the Mahamaham eve which was world wide popular in Kumbakonam. He accepted the company and stepped into his career.
</p>
                <p className="otherPara">He started a small soda company, "Silver star" in 1992 and had only one person working for him that day. He and his co-worker began to work together. A change in his life progressed little by little in 1998 when the soda company stumbled due to the invasion of multinational companies and increasing plastics. In 1998 he started selling Rasna soft drinks in pockets which was a great success. Then he went to Cuddalore and acquired the permission of marketing water pockets from a private company Alps. Soon he realised that the market for mineral water was bright. As a result on 12th March of 2000, he started a mineral water factory named Pure today which was the fruit of 8 years of struggles and achievement in the soda company. But that company was banned because of the law created by the Government to regulate mineral water factories with ISI certification for producing drinking water. He made great attempts to turn a small start up company to ISI branded large Factory. His Winway water company was launched on March 22,2004 on World water day with ISI certification at Mangudi,Valayapettai, Kumbakonam. In between, he was married to Latha, the 6th daughter of D.Rajendran and Rajeshwari from Kanchipuram on October 29, 2001. She works as a teacher in Government Aided school and has two daughters, Sasi priya and Jina sri lakshmi.
</p>
                <p className="otherPara"><b>BUSINESS ACCOLADES</b><br />
                Starting his career as a small soda company owner in 1992 and rose to owner of a mineral water factory in 2004. His journalism and writing career which began in 1982 has now risen to Author of books and article series writer in Kalki weekly. Meanwhile in 2001, he incorporated himself into the Rotary Organization. He was a member of the Kumbakonam Rotary Club in 2001 and served as its Secretary in 2008-2009, President in 2012-2013 and Assistant Governor in 2017-2018. The Rotary blood bank was started in Kumbakonam in 2012 with Mohammad Ziauddin when he was its chairman, and is still functioning well.He also served as the Chairman of the KUmbakonam Chamber of Commerce from 2012-2014. He is the president of the Kumbakonam Drinking water and Cooldrinks Welfare Association and State vice president of the Greater Tamil Nadu Packaged Drinking Water Manufacturers Association in Chennai.
</p>
              </div>
              <div className="imgAbout">
                <div className="roundAnimation">
                  <img src={require('../../assets/person2.png')} />
                </div>
              </div>
            </div>
            <div className="logoCnt">
              <img src={require('../../assets/saiKiruba.png')} />
              <a href={'http://www.winway.in/'} target="_blank">
                <img src={require('../../assets/milestone_02.png')} />
              </a>
              <img src={require('../../assets/saiKiruba.png')} />
            </div>
          </div>

        </div>
        <div className="dropCnt">
          <a href={'../resources/template-around-kumbakonam.jpg'} target="_blank">
            <div className="dropDown">
              <span>Temples around Kumbakonam<FontAwesomeIcon className="floatRight dropdownIcon" icon={faAngleDown} /></span>
            </div>
          </a>
          <div className="dropDown" id='otherPilgrimages' onClick={() => this.toggleClass('otherPilgrimages')}>
            <span>Other pilgrimages along navagraha route<FontAwesomeIcon className="floatRight dropdownIcon" icon={faAngleDown} /></span>
            <img className={this.state.otherPilgrimages ? 'show' : 'hide'} src={require('../../assets/c1.png')} />
          </div>
          <div className="dropDown" id='nearestTourism' onClick={() => this.toggleClass('nearestTourism')}>
            <span>Nearest tourism along navagraha route<FontAwesomeIcon className="floatRight dropdownIcon" icon={faAngleDown} /></span>
            <img className={this.state.nearestTourism ? 'show' : 'hide'} src={require('../../assets/c2.png')} />
          </div>
          <div className="dropDown" id='unescoTemples' onClick={() => this.toggleClass('unescoTemples')}>
            <span>UNESCO temples<FontAwesomeIcon className="floatRight dropdownIcon" icon={faAngleDown} /></span>
            <img className={this.state.unescoTemples ? 'show' : 'hide'} src={require('../../assets/banner3.png')} />
          </div>
        </div>
        <img className={this.state.showDottedAnim ? "dottedAnim" : "hidden"} src={require('../../assets/dottedpattern.png')} />
        <div className={this.state.showDottedAnim ? "moveToTop" : "nameAnim"}><img src={require('../../assets/rotarylogo.png')} /><p>Loga chandraprabhu</p></div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Home;
