import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class MapContainer extends React.Component {
    render() {        
        return (
            <div>
                <div className="lgScr">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1dB9I8BgRp1_9jTEBUu4wFUUDK4SFSSpD" width="100%" height="270px"></iframe>
                </div>
                <div className="mblScr">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1dB9I8BgRp1_9jTEBUu4wFUUDK4SFSSpD" width="100%" height="250px"></iframe>
                </div>
            </div>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyAP2EeLc8qttv7azYrs_v3NjeBXV0U-0SA'),
    version: 3.31
})(MapContainer);
