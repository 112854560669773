import React, { Component } from 'react';
import './gallery.scss';
import Footer from '../../components/footer/footer';
import Nav from '../../components/navbar/nav';
import Datepicker from '../../components/daterangepicker/datepicker';
const config = require('../../config');
class Gallery extends Component {
  constructor() {
    super()
    this.state = {
      events: [],
      dataRange: [],
      currMnth: [new Date().getMonth()],
      currMnthEvent: [],
      eventVisible: {},
      popUp: {}
    }
  }
  componentDidMount() {
    fetch(config['baseurl'] + 'fetchgallery.php', {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        let currMnthEve = []
        let currMnth = this.state.currMnth
        let eventVisibleData = {};
        let popData = {}
        data.map(function (lineItem) {
          eventVisibleData[lineItem['id']] = false;
          popData[lineItem['id']] = false;
          if (currMnth.indexOf(new Date(lineItem['date']).getMonth()) > -1) {
            currMnthEve.push(lineItem)
          }
        });
        this.setState({
          eventVisible: eventVisibleData,
          events: data,
          currMnthEvent: currMnthEve,
          popUp: popData
        });
      });
  }
  onCalenderSelect = (param) => {
    let currMnthEve = []
    let currMnth = this.getAllSelectedMnths({
      "startDate": new Date(param.startDate),
      "endDate": new Date(param.endDate)
    })
    this.state.events.map(function (lineItem) {
      if (currMnth.indexOf(new Date(lineItem['date']).getMonth()) > -1) {
        currMnthEve.push(lineItem)
      }
    });
    this.setState({
      currMnthEvent: currMnthEve
    });
  }
  getAllSelectedMnths = (param) => {
    let allMnth = [];
    allMnth.push(param.startDate.getMonth())
    while (param.endDate.getMonth() != allMnth[allMnth.length - 1]) {
      allMnth.push(allMnth[allMnth.length - 1] + 1)
    }
    return allMnth;
  }
  showEvent = (eventid) => {
    let thisObj = this.state.eventVisible;
    for (let key in thisObj) {
      thisObj[key] = false;
    }
    thisObj[eventid] = true;
    this.setState({
      eventVisible: thisObj
    })
    this.showThisCard(eventid);
  }
  showThisCard = (cardID) => {
    setTimeout(function () {
      document.querySelector('[tarevt-id="' + cardID + '"]').scrollIntoView({ behavior: "smooth", block: "start" });
    }, 70);
  }
  showPopUp = (imgId) => {    
    let thisObj = this.state.popUp;
    for (let key in thisObj) {
      thisObj[key] = false;
    }
    thisObj[imgId] = true;
    this.setState({
      popUp: thisObj
    })
  }
  closePopUp = () => {
    console.log('close');
    let thisObj = this.state.popUp;
    for (let key in thisObj) {
      thisObj[key] = false;
    }    
    this.setState({
      popUp: thisObj
    })
  }
  render() {
    let showEvent = this.showEvent;
    let thisState = this.state;
    let galleryThis = this;
    return (
      <div className="container" data-gallerycnt="true">        
        <Nav />
        <div className="galleryCnt">
          <h3>Events</h3>
          <div className="eventBlk">
            <div className="eventTextDate">
              {this.state.currMnthEvent.map(function (item, index) {
                let thisDate = new Date(item['date']);
                thisDate = thisDate.getDate() + '/' + (Number(thisDate.getMonth())+1) + '/' + thisDate.getFullYear();
                return <p className="curPointer" key={item['id']} onClick={() => showEvent(item['id'])}>{thisDate + ' - ' + item['textcnt']}</p>
              })}
            </div>
            <div className="datePickerCnt">
              <Datepicker triggerFunc={this.onCalenderSelect} />
            </div>
          </div>
          <div className="eventCnt">
            {this.state.events.map(function (item, index) {
              return <div className={thisState.eventVisible[item['id']] ? 'eventCard' : 'hidden'} key={item['id']} tarevt-id={item['id']}>
                <p className="cardTitle">{item['title']}</p>
                <div className="eventTxt">
                  <p>{item['textcnt']}</p>
                </div>
                <p className="secHead">Gallery view</p>
                <div className="imgCnt">
                  <img onClick={() => galleryThis.showPopUp(item['id'])} src={config['baseurl'] + 'resources/' + item['img']} />
                </div>
              </div>
            })}
          </div>
          {this.state.events.map(function (item, index) {
            return <div onClick={() => galleryThis.closePopUp()} className={thisState.popUp[item['id']] ? 'overlay' : 'overlayNone'} key={item['id']}>
              <div className="popup">
                <div className="imgCnt">
                  <img src={config['baseurl'] + 'resources/' + item['img']} />
                </div>
              </div>
            </div>
          })}
          {/* <div className="galleryBlk">
            {this.state.events.map(function (item, index) {
              return <div className="eventCard" key={item['id']}>
                <div className="imgCnt">
                  <img src={config['baseurl'] + 'resources/' + item['img']} />
                </div>
                <div className="eventTxt">
                  <p>{item['textcnt']}</p>
                </div>
              </div>
            })}
          </div> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Gallery;
