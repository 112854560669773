import React, { Component } from 'react';
import './style.css';
import { Redirect } from "react-router-dom";
class Temp extends Component {
    constructor() {
        super()
        this.state = {
            home: false
        }
    }
    login = (event) => {        
        this.setState({ home: true });
    }
    render() {
        if (this.state.home) {
            return (
                <Redirect to='./home' />
            )
        } else {
            return (
                <section className="fh5co-about-me">
                    {/* <span onClick={this.login}>move to home</span> */}
                    <div className="about-me-inner site-container">
                        <article className="portfolio-wrapper">
                            <div className="portfolio__img">
                                <img src={require('./images/about-me.jpg')} className="about-me__profile" alt="about me profile picture" />
                            </div>
                            <div className="portfolio__bottom">
                                <div className="portfolio__name">

                                    <h2 style={{ fontSize: "20px" }}>Logachandraprabhu</h2>
                                </div>
                                <p>ENTERPRENUER,<br />WRITER,<br />SOCIALIST,<br />ACTOR</p>
                            </div>
                        </article>
                        <div className="about-me__text">
                            <div className="about-me-slider">
                                <div className="about-me-single-slide">
                                    <h2 className="universal-h2 universal-h2-bckg">WELCOME TO MY WEBSITE</h2>
                                    <p><span>Coming Soon</span>......</p>
                                    <h4>Author</h4>
                                    <p className="p-white">See me</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="about-me-bckg"></div>
                </section>
            );
        }
    }
}

export default Temp;