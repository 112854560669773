import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './courosel.scss';
class BannerCarousel extends Component {
    render() {
        const getConfigurableProps = () => ({
            showArrows: false,
            infiniteLoop: true,
            showStatus: false,
            showThumbs: false,
            autoPlay: true,
            stopOnHover: true,
            interval: 3000,
            transitionTime: 300,
            swipeScrollTolerance: 5
        });
        return (
            <div className="couroselCnt">
                <Carousel {...getConfigurableProps()}>
                    <div>
                        <img src={require('../../assets/book4.jpg')} />
                    </div>
                    <div>
                        <img className="notFull" src={require('../../assets/book4_1.jpg')} />
                    </div>
                    <div>
                        <img className="notFull" src={require('../../assets/book4_2.jpg')} />
                    </div>
                    <div>
                        <img src={require('../../assets/book1.jpg')} />
                    </div>
                    <div>
                        <img src={require('../../assets/book2.jpg')} />
                    </div>
                    <div>
                        <img src={require('../../assets/book3.jpg')} />
                    </div>
                </Carousel>
            </div>
        );
    }
}

export default BannerCarousel
