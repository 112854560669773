import React from 'react';
import './footer.scss';
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
      <div className="footerCnt">
        <p className="lgscr"><Link to="/home">Home</Link> | <Link to="/gridview">Gallery</Link> | <Link to="/gallery">Events</Link> | <Link to='/contactus'>Contact&nbsp;Us</Link></p>
        <p className="copyright">Copyright © 2020 www.logachandraprabhu.com | Powered by : RS Websolutions</p>
      </div>
    </div>
  )
}
export default Footer;