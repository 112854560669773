import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route  
} from "react-router-dom";
import Home from './pages/home/home';
import Gallery from './pages/gallery/gallery';
import Contactus from './pages/contactus/contactus';
import Homelang from './pages/homelang/homelang';
import Gallerylang from './pages/gallerylang/gallerylang';
import Contactuslang from './pages/contactuslang/contactuslang';
import Admin from './pages/admin/admin';
import Dynamic from './pages/dynamic/dynamic';
import Gridview from './pages/gridview/gridview'
import Gridviewlang from './pages/gridviewlang/gridviewlang'
import Temp from './pages/temp/temp';
export default function App() {
  return (
    document.title = "Loga Chandraprabu",
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/index.php">
          <Home />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/home.php">
          <Home />
        </Route>
        <Route exact path="/homelang">
          <Homelang />
        </Route>
        <Route exact path="/homelang.php">
          <Homelang />
        </Route>                
        <Route path="/gridviewlang">
          <Gridviewlang />
        </Route>        
        <Route path="/gridview">
          <Gridview />
        </Route>
        <Route path="/gridview.php">
          <Gridview />
        </Route>
        <Route path="/gallery">
          <Gallery />
        </Route>
        <Route path="/gallery.php">
          <Gallery />
        </Route>
        <Route path="/gallerylang">
          <Gallerylang />
        </Route>
        <Route path="/gallerylang.php">
          <Gallerylang />
        </Route>
        <Route path="/Contactus">
          <Contactus />
        </Route>
        <Route path="/Contactus.php">
          <Contactus />
        </Route>
        <Route path="/Contactuslang">
          <Contactuslang />
        </Route>
        <Route path="/Contactuslang.php">
          <Contactuslang />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/admin.php">
          <Admin />
        </Route>
        <Route path="/admincontent">
          <Dynamic />
        </Route>
        <Route path="/admincontent.php">
          <Dynamic />
        </Route>
      </Switch>
    </Router>
  );
}