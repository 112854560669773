import React, { Component } from 'react';
import './contactuslang.scss';
import Footerlang from '../../components/footerlang/footerlang';
import Navlang from '../../components/navbarlang/navlang';
import MapContainer from '../../components/map/map';
const config = require('../../config');
class Contactuslang extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      phone: '',
      email: '',
      msg: ''
    }
  }
  sendmail = () => {
    let sObj = JSON.stringify({
      "first_name": this.state.username,
      "email": this.state.email,
      "telephone": this.state.phone,
      "comments": this.state.msg
    });
    const requestOptions = {
      method: 'POST',
      body: sObj
    };
    fetch(config['baseurl'] + 'send.php', requestOptions);
    this.setState({
      username: '',
      phone: '',
      email: '',
      msg: ''
    });      
  }
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }
  render() {
    return (
      <div className="container lang" data-contus="true">
        <Navlang />
        <div className="contactusCnt">
          <div className="addBlk">
            <div className="addCont">
              <h2>தொடர்பு முகவரி</h2>
              <p>லோக சந்திரபிரபு</p>
              <p>மகிழ்ச்சி இல்லம்</p>              
              <p>27/D பக்தபுரி தெரு,</p>
              <p>கும்பகோணம்-612001.</p>
              <p>தொலைபேசி:9344301219</p>
              <p>மின்னஞ்சல்:winwaywater@gmail.com</p>
            </div>
            <div className="mapCont"><MapContainer /></div>
          </div>
          <h2>தொடர்பு கொள்ள</h2>
          <div className="contactForm">
            <div className="msgForm">
              <div className="msgFormLabel">
                <p>
                  பெயர்
              </p>
                <p>
                  தொலைபேசி
              </p>
                <p>
                  மின்னஞ்சல்
              </p>
                <p>
                  செய்தி
              </p>
              </div>
              <div className="msgFormInput">
                <p>
                  <input type="text" name="username" value={this.state.username} onChange={this.myChangeHandler}></input>
                </p>
                <p>
                  <input type="text" name="phone" value={this.state.phone} onChange={this.myChangeHandler}></input>
                </p>
                <p>
                  <input type="text" name="email" value={this.state.email} onChange={this.myChangeHandler}></input>
                </p>
                <p>
                  <textarea type="text" name="msg" value={this.state.msg} onChange={this.myChangeHandler}></textarea>
                </p>
              </div>
            </div>
            <p className="tempEmailCnt">Email: winwaywater@gmail.com</p>
            <p className="contactusBtnCnt">
              <span className="btn btnPrimary floatRight" onClick={this.sendmail}>சமர்ப்பிக்கவும்</span>
            </p>
          </div>
        </div>
        <Footerlang />
      </div>
    );
  }
}

// export default GoogleApiWrapper({
//   apiKey: ('AIzaSyAP2EeLc8qttv7azYrs_v3NjeBXV0U-0SA'),  
//  })(Contactus);
export default Contactuslang;
