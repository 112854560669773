import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt, faBars } from '@fortawesome/free-solid-svg-icons'

import './navlang.scss';
function Navlang() {
  return (
    <div className="menuBarWrap">
      <div className="menuBar lang">
        <div className="nameCnt">
          <img src={require('../../assets/person3.png')} />
          <div>
            <h3>லோக சந்திரபிரபு</h3>
            <p className="headAbt">நடிகர், எழுத்தாளர், அரசியல்வாதி, சமூக ஆர்வலர்</p>
          </div>
        </div>
        <div className="phoneNum">
          <p className="phNumBlk"><FontAwesomeIcon className="phoneIcon" icon={faPhoneSquareAlt} />&nbsp;9344301219</p>
          <p className="langSwitch"><span><Link to="/home">English</Link></span>&nbsp;|&nbsp;<span className="underline">தமிழ்</span></p>
        </div>
        <div className="phoneNumMbl">
          <p className="phNumBlk">
            <FontAwesomeIcon className="phoneIcon" icon={faPhoneSquareAlt} /> <a href="tel:9344301219">9344301219</a></p>
          <p className="langSwitch"><span><Link to="/home">English</Link></span>&nbsp;|&nbsp;<span className="underline">தமிழ்</span></p>
        </div>
        <div className="menuItems">
          <div className="btn" data-home="true">
            <Link to="/homelang">முதன்மை</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-grid="true">
            <Link to="/gridviewlang">காட்சியகங்கள்</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-gallery="true">
            <Link to="/gallerylang">நிகழ்வுகள்</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-cont="true">
            <Link to='/contactuslang'>தொடர்பு கொள்ள</Link>
            <span className="selcted"></span>
          </div>
        </div>
      </div>
      <div className="mobileMenu lang">
        <div className="menuItemsMbl lang">
          <div className="btn" data-home="true">
            <Link to="/homelang">முதன்மை</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-grid="true">
            <Link to="/gridviewlang">காட்சியகங்கள்</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-gallery="true">
            <Link to="/gallerylang">நிகழ்வுகள்</Link>
            <span className="selcted"></span>
          </div>
          <div className="btn" data-cont="true">
            <Link to='/contactuslang'>தொடர்பு கொள்ள</Link>
            <span className="selcted"></span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Navlang;