import React, { Component } from 'react';
import './contactus.scss';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Nav from '../../components/navbar/nav';
import MapContainer from '../../components/map/map';
const config = require('../../config');
class Contactus extends Component {
  constructor() {
    super()
    this.state = {
      username: '',
      phone: '',
      email: '',
      msg: ''
    }
  }
  sendmail = () => {    
    let sObj = JSON.stringify({
      "first_name": this.state.username,
      "email": this.state.email,
      "telephone": this.state.phone,
      "comments": this.state.msg
    });
    const requestOptions = {
      method: 'POST',
      body: sObj
    };
    fetch(config['baseurl'] + 'send.php', requestOptions);
    this.setState({
      username: '',
      phone: '',
      email: '',
      msg: ''
    });
  }
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }
  render() {
    return (
      <div className="container" data-contus="true">
        <Nav />
        <div className="contactusCnt">
          <div className="addBlk">
            <div className="addCont">
              <h2>Contact address</h2>
              <p>Loga Chandraprabhu</p>
              <p>Makilchi Illam</p>
              <p>27/D Bakthapuri Street,</p>              
              <p>Kumbakonam-612001.</p>
              <p>Phone:9344301219</p>
              <p>Email:winwaywater@gmail.com</p>
            </div>
            <div className="mapCont">
              <MapContainer />
            </div>
          </div>
          <h2>Contact us</h2>
          <div className="contactForm">
            <div className="msgForm">
              <div className="msgFormLabel">
                <p>
                  Name
              </p>
                <p>
                  Phone
              </p>
                <p>
                  Email
              </p>
                <p>
                  Message
              </p>
              </div>
              <div className="msgFormInput">
                <p>
                  <input type="text" name="username" value={this.state.username} onChange={this.myChangeHandler}></input>
                </p>
                <p>
                  <input type="text" name="phone" value={this.state.phone} onChange={this.myChangeHandler}></input>
                </p>
                <p>
                  <input type="text" name="email" value={this.state.email} onChange={this.myChangeHandler}></input>
                </p>
                <p>
                  <textarea type="text" name="msg" value={this.state.msg} onChange={this.myChangeHandler}></textarea>
                </p>
              </div>
            </div>
            <p className="tempEmailCnt">Email: winwaywater@gmail.com</p>
            <p className="contactusBtnCnt">
              <span className="btn btnPrimary floatRight" onClick={this.sendmail}>Submit</span>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default GoogleApiWrapper({
//   apiKey: ('AIzaSyAP2EeLc8qttv7azYrs_v3NjeBXV0U-0SA'),  
//  })(Contactus);
export default Contactus;
